import * as React from "react"
import StartPageSearch from "../components/search/StartPageSearch";

import {MetaHeader} from "../components/common/MetaHeader";
import {Layout} from "../components/common/Layout";


// markup
const IndexPage = () => {
    return (
        <Layout>
            <StartPageSearch/>
        </Layout>
    )
}

export default IndexPage

export const Head = () => (
    <MetaHeader/>
)
