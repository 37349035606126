import React from "react";
import Search from "./Search";

export default function StartPageSearch() {

    return (
        <>
            <h1>Запчастини Metabo</h1>
            <Search/>
        </>
    )
}
